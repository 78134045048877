import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { GENDER_LIST } from "../../constants/config";
import SwitchUI from "components/SwitchUI";
import {
  getLookupListAction,
  uploadFileAction,
  saveProductDetailsAction,
  getProductDetailsAction,
} from "../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
  Container,
  Modal,
} from "reactstrap";
import { useHistory, Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import ReactModal from "react-modal-resizable-draggable";
const AddEditProduct = props => {
  const history = useHistory();
  const [selectedImageFiles, setselectedImageFiles] = useState([]);
  const [selectedFBXFiles, setselectedFBXFiles] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [fbxModalLoaded, setFbxModalLoaded] = useState(false);
  const [categoryFltList, setCategoryFltList] = useState([]);
  let initialState = {
    id: props.id,
    isPublish: false,
    name: "",
    categoryId: "",
    productTypeId: "",
    gender: "",
    price: "",
    vendorCode: "",
    fbxFile: "",
    fbxFileSize: 0,
    image: "",
    imageSize: 0,
    tags: "",
    status: 1,
    remarks: "",
    skuList: [{ sizeId: 0, colorId: 0, skuCode: "" }],
  };
  const [formData, setFormData] = useState(initialState);
  const [show, setShow] = useState(false);
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  function tog_backdrop(status) {
    setShow(status);
    removeBodyCss();
  }
  useEffect(() => {
    props.setLoading(true);
    getLookupListAction("ProductType")
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            setProductTypeList(resp.data.rows);
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
    getLookupListAction("Category")
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            setCategoryList(resp.data.rows);
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
    getLookupListAction("ProductSize")
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            setSizeList(resp.data.rows);
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
    getLookupListAction("ProductColor")
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            setColorList(resp.data.rows);
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
    if (props.id) {
      LoadProductDetails(props.id);
    }
  }, [props.id]);
  useEffect(() => {
    let d = categoryList.filter(
      v => v.parentId === parseInt(formData.productTypeId)
    );
    setCategoryFltList(d);
  }, [formData.productTypeId, categoryList]);

  const LoadProductDetails = id => {
    props.setLoading(true);
    getProductDetailsAction(id)
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let respdata = resp.data;
            let fData = JSON.parse(JSON.stringify(initialState));
            fData.name = respdata.name;
            fData.isPublish = respdata.isPublish;
            fData.categoryId = `${respdata.categoryId}`;
            fData.productTypeId = `${respdata.productTypeId}`;
            fData.gender = respdata.gender;
            fData.price = respdata.price;
            fData.vendorCode = respdata.vendorCode;
            fData.fbxFile = respdata.fbxFile;
            fData.fbxFileSize = respdata.fbxFileSize;
            fData.remarks = respdata.remarks;
            fData.image = respdata.image;
            fData.imageSize = respdata.imageSize;
            fData.tags = respdata.tags;
            fData.status = respdata.status;
            fData.skuList = respdata.skuList;
            setFormData(fData);
          } else {
            toast.error("Invalid request");
            history.push("/products");
          }
        } else {
          toast.error("Invalid request");
          history.push("/products");
        }
      })
      .catch(err => {
        toast.error("Invalid request");
        history.push("/products");
        props.setLoading(false);
      });
  };
  const handleChange = (name, value) => {
    let fData = JSON.parse(JSON.stringify(formData));
    fData[name] = value;
    if (name === "productTypeId") {
      let d = categoryList.filter(v => v.parentId === parseInt(value));
      setCategoryFltList(d);
    }
    setFormData(fData);
  };
  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setselectedImageFiles(files);
  };
  const handleAcceptedFBXFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setselectedFBXFiles(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const handleSaveBtnClick = async () => {
    if (formData.name.trim().length < 1) {
      toast.error(`Please enter a Valid display name`);
      return false;
    } else if (formData.productTypeId.length < 1) {
      toast.error(`Please select product type`);
      return false;
    } else if (formData.categoryId.length < 1) {
      toast.error(`Please select Product Category`);
      return false;
    } else if (formData.gender.length < 1) {
      toast.error(`Please select Gender`);
      return false;
    }
    let skuIDS = {},
      errorChk = false;
    for (let i in formData.skuList) {
      if (formData.skuList[i].skuCode.length > 0) {
        if (skuIDS.hasOwnProperty(formData.skuList[i].skuCode)) {
          skuIDS[formData.skuList[i].skuCode]++;
          errorChk = true;
          toast.error(`Duplicate SKU Code ${formData.skuList[i].skuCode}`);
        } else {
          skuIDS[formData.skuList[i].skuCode] = 0;
        }
      } else {
        errorChk = true;
        toast.error(`Please enter SKU Code`);
      }
    }
    if (errorChk) {
      return false;
    }
    props.setLoading(true);
    try {
      let fdata = JSON.parse(JSON.stringify(formData));
      fdata.price = parseFloat(fdata.price) ? parseFloat(fdata.price) : 0;
      fdata.categoryId = parseInt(fdata.categoryId);
      fdata.productTypeId = parseInt(fdata.productTypeId);
      if (selectedImageFiles.length > 0) {
        fdata.imageSize = parseFloat(
          (selectedImageFiles[0].size / 1024).toFixed(2)
        );
        let requestBody = {
          fileName: selectedImageFiles[0].name,
          contentType: selectedImageFiles[0].type,
          uploadTag: "itemImageFile",
        };
        let res = await uploadFileHandler(requestBody, selectedImageFiles[0]);
        fdata.image = res.filePath;
      }

      if (selectedFBXFiles.length > 0) {
        fdata.fbxFileSize = parseFloat(
          (selectedFBXFiles[0].size / 1024).toFixed(2)
        );
        let requestBody = {
          fileName: selectedFBXFiles[0].name,
          contentType: selectedFBXFiles[0].type,
          uploadTag: "itemFbxFile",
        };
        let res = await uploadFileHandler(requestBody, selectedFBXFiles[0]);
        fdata.fbxFile = res.filePath;
      }
      if (!fdata.id) {
        delete fdata.id;
      }
      saveProductDetailsAction(fdata.id ? "put" : "post", fdata, fdata.id)
        .then(resp => {
          props.setLoading(false);
          if (resp.httpCode >= 200 && resp.httpCode <= 299) {
            toast.success("Product Information saved successfully");
            setFormData(initialState);
            history.push("/products");
          } else {
            toast.error(
              "Something went wrong! Please try again or contact to Administration Department"
            );
          }
        })
        .catch(err => {
          props.setLoading(false);
          let msg =
            "Something went wrong! Please try again or contact to Administration Department";
          if (err.response) {
            if (err.response.data) {
              if (Array.isArray(err.response.data.errors)) {
                msg = "";
                for (let i in err.response.data.errors) {
                  msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
                }
              }
            }
          }
          toast.error(msg);
        });
    } catch (err) {
      props.setLoading(false);
      toast.error(typeof err === "string" ? err : "Something went wrong");
      console.log(".........", err);
    }
  };
  const uploadFileHandler = (requestBody, file) => {
    return new Promise((Resolved, Reject) => {
      uploadFileAction(requestBody)
        .then(d => {
          fetch(d.data.url, {
            method: "PUT",
            body: file,
          })
            .then(uploadStatus => {
              Resolved(d.data);
            })
            .catch(err => {
              console.log("error", err);
              Reject("Error in uploading");
            });
        })
        .catch(err => {
          Reject("Error in uploading");
        });
    });
  };
  const removeImage = file => {
    const newFiles = [...selectedImageFiles]; // make a var for the new array
    newFiles.splice(file, 1); // remove the file from the array
    setselectedImageFiles(newFiles); // update the state
    return false;
  };
  const removeFBXFile = file => {
    const newFiles = [...selectedFBXFiles]; // make a var for the new array
    newFiles.splice(file, 1); // remove the file from the array
    setselectedFBXFiles(newFiles); // update the state
    return false;
  };
  const loadFBXFileHandler = () => {
    //tog_backdrop(true);
    if (!fbxModalLoaded) {
      window.DigiStyler.initialize({
        mountPoint: "#digiStyler-root",
        loadedCallBack: function () {
          setFbxModalLoaded(true);
        },
      });
      window.DigiStyler.drawDS();
    } else {
      window.DigiStyler.changeProduct(formData.vendorCode);
    }
  };
  const previewFBXFileHandler = () => {
    window.DigiStyler.changeProduct(formData.vendorCode);
  };
  const handleChangeSkuList = (ind, key, value) => {
    let d = JSON.parse(JSON.stringify(formData));
    d.skuList[ind][key] = value;
    setFormData(d);
  };
  const skuUpdateHandler = (op, ind) => {
    let d = JSON.parse(JSON.stringify(formData));
    if (op === "add") {
      d.skuList.push({ sizeId: 0, colorId: 0, skuCode: "" });
    } else {
      d.skuList = d.skuList.filter((v, i) => i !== ind);
    }
    setFormData(d);
  };
  return (
    <>
      <MetaTags>
        <title>Add/Edit Product | NextGen Admin Portal</title>
      </MetaTags>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <CardTitle>
                Product Information
                <Link
                  style={{ float: "right" }}
                  className="btn btn-danger"
                  to="/products"
                >
                  Back
                </Link>
              </CardTitle>
              <CardSubtitle className="mb-4">
                Fill all information below
              </CardSubtitle>

              <Form method="post" action="#">
                <Row className="pb-2" md={3} sm={2} xs={1}>
                  <Col className="pt-3">
                    <Label htmlFor="name">Display Name *</Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      value={formData.name}
                      maxLength={100}
                      placeholder="Display Name"
                      className="form-control"
                    />
                  </Col>
                  <Col className="pt-3">
                    <Label className="control-label">Product Type *</Label>
                    <select
                      id="productTypeId"
                      name="productTypeId"
                      className="form-select select2"
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      value={formData.productTypeId}
                    >
                      <option value="">---Select Product Type---</option>
                      {productTypeList.map((v, i) => {
                        return (
                          <option key={`title-${i}`} value={v.ID}>
                            {v.name}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col className="pt-3">
                    <Label className="control-label">Product Category *</Label>
                    <select
                      id="categoryId"
                      name="categoryId"
                      className="form-select select2"
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      value={formData.categoryId}
                    >
                      <option value="">---Select Category---</option>
                      {categoryFltList.map((v, i) => {
                        return (
                          <option key={`title-${i}`} value={v.ID}>
                            {v.name}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col className="pt-3">
                    <Label className="control-label">Gender *</Label>
                    <select
                      name="gender"
                      id="gender"
                      className="form-select select2"
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      value={formData.gender}
                    >
                      <option value="">---Select Gender---</option>
                      {Object.keys(GENDER_LIST).map((v, i) => {
                        return (
                          <option key={`title-${i}`} value={v}>
                            {GENDER_LIST[v]}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col className="pt-3">
                    <Label htmlFor="price">Price </Label>
                    <Input
                      id="price"
                      name="price"
                      type="number"
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      value={formData.price}
                      maxLength={10}
                      placeholder="Price"
                      className="form-control"
                    />
                  </Col>
                  <Col className="pt-3">
                    <Label htmlFor="vendorCode">Vendor Code</Label>
                    <Input
                      id="vendorCode"
                      name="vendorCode"
                      type="text"
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      value={formData.vendorCode}
                      maxLength={50}
                      placeholder="Vendor Code"
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4} sm={12} xs={12}>
                    <Row md={2}>
                      <Col>
                        <Label className="form-label">Status *</Label>
                        <br />
                        <SwitchUI
                          parentCallback={value => {
                            handleChange("status", value ? 1 : 0);
                          }}
                          offText="Off"
                          onText="On"
                          onColor="#00A300"
                          offColor="#d9534f"
                          checked={formData.status === 0 ? false : true}
                        />
                      </Col>
                      <Col>
                        <Label className="form-label">Publish</Label>
                        <br />
                        <SwitchUI
                          parentCallback={value => {
                            handleChange("isPublish", value ? true : false);
                          }}
                          offText="No"
                          onText="Yes"
                          onColor="#d9534f"
                          checked={formData.isPublish === 1 ? true : false}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={8} sm={12} xs={12}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>SKU Code *</th>
                          <th>Color</th>
                          <th>Size</th>
                          <th>-</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(formData.skuList)
                          ? formData.skuList.map((v, i) => {
                              return (
                                <tr key={`key-Att-${i}`}>
                                  <td>
                                    <Input
                                      type="text"
                                      onChange={e => {
                                        handleChangeSkuList(
                                          i,
                                          "skuCode",
                                          e.target.value
                                        );
                                      }}
                                      value={v.skuCode}
                                      maxLength={50}
                                      placeholder="SKU Code"
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <select
                                      className="form-select select2"
                                      onChange={e => {
                                        handleChangeSkuList(
                                          i,
                                          "colorId",
                                          parseInt(e.target.value)
                                        );
                                      }}
                                      value={v.colorId}
                                    >
                                      <option value="0">
                                        ---Select Color---
                                      </option>
                                      {colorList.map((v, i) => {
                                        return (
                                          <option
                                            key={`color-${i}`}
                                            value={v.ID}
                                          >
                                            {v.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </td>
                                  <td>
                                    <select
                                      className="form-select select2"
                                      onChange={e => {
                                        handleChangeSkuList(
                                          i,
                                          "sizeId",
                                          parseInt(e.target.value)
                                        );
                                      }}
                                      value={v.sizeId}
                                    >
                                      <option value="0">
                                        ---Select Size---
                                      </option>
                                      {sizeList.map((v, i) => {
                                        return (
                                          <option
                                            key={`size-${i}`}
                                            value={v.ID}
                                          >
                                            {v.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() =>
                                        skuUpdateHandler("minus", i)
                                      }
                                    >
                                      <i className="fas fa-minus" />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                    <p align="right" style={{ marginRight: "2%" }}>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => skuUpdateHandler("add")}
                      >
                        <i className="fas fa-plus-circle" />
                      </button>
                    </p>
                  </Col>
                </Row>
                <Row md={2} sm={1} xs={1}>
                  <Col className="pt-3">
                    <Label htmlFor="vendorCode">Product Image</Label>
                    <Dropzone
                      accept="image/*"
                      multiple={false}
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} accept="*/" />
                            <div className="dz-message needsclick">
                              {selectedImageFiles.length === 0 ? (
                                <>
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </>
                              ) : (
                                selectedImageFiles.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                          </Col>
                                          <Col>
                                            <small className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </small>
                                            <Link
                                              style={{ float: "right" }}
                                              to="#"
                                              onClick={removeImage}
                                              className="text-danger fa fa-times-circle"
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {props.id && formData.image ? (
                      <p align="center" className="pt-2">
                        <strong>Uploaded Image File: </strong>
                        <img
                          alt="product Image"
                          src={formData.image}
                          width="100"
                        />
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col className="pt-3">
                    <Label htmlFor="vendorCode">FBX File</Label>
                    <Dropzone
                      multiple={false}
                      onDrop={acceptedFiles => {
                        handleAcceptedFBXFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} accept="*/" />
                            <div className="dz-message needsclick">
                              {selectedFBXFiles.length === 0 ? (
                                <>
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </>
                              ) : (
                                selectedFBXFiles.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                          </Col>
                                          <Col>
                                            <small className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </small>
                                            <Link
                                              style={{ float: "right" }}
                                              to="#"
                                              onClick={removeFBXFile}
                                              className="text-danger fa fa-times-circle"
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  );
                                })
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {props.id && formData.fbxFile ? (
                      <>
                        <p align="center" className="pt-2">
                          <strong>Uploaded FBX File: </strong>
                          {formData.fbxFile.split("/").pop()}
                          <br />
                          <div id="digiStyler-root"></div>
                          <Button
                            onClick={loadFBXFileHandler}
                            className="btn btn-primary mt-2"
                          >
                            {fbxModalLoaded ? "Preview Product" : "Load FBX"}
                          </Button>
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row className="pb-5" md={1} sm={1} xs={1}>
                  <Col>
                    <Label htmlFor="vendorCode">Remarks</Label>
                    <textarea
                      id="remarks"
                      name="remarks"
                      type="text"
                      value={formData.remarks}
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      maxLength={200}
                      className="form-control"
                    ></textarea>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    type="button"
                    onClick={handleSaveBtnClick}
                    color="success"
                    className="btn "
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <div className="resizer-modal-custom">
          <ReactModal
            initWidth={400}
            initHeight={580}
            onFocus={() => console.log("Modal is clicked")}
            className={"my-modal-custom-class"}
            onRequestClose={() => setShow(false)}
            isOpen={show}
            disableResize={true}
            top={100 - window.screen.height}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                FBX Preview
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setShow(false);
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Container>
                <div
                  id="digiStyler-root"
                  style={{ height: "380px", width: "580px" }}
                ></div>
                <p align="center" className="pt-3">
                  <Button
                    onClick={previewFBXFileHandler}
                    className="btn btn-primary mt-2"
                  >
                    Preview FBX
                  </Button>
                </p>
              </Container>
            </div>
          </ReactModal>
        </div>
      </Row>
    </>
  );
};

export default IsLoadingHOC(AddEditProduct, "Wait .....");
