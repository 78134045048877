import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import DatatableTables from "../../components/Tables/DatatableTables";
import {
  getNotificationListAction,
  updateNotificationReadStatusAction,
} from "../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { Link, useParams } from "react-router-dom";
const Inbox = props => {
  const [columns, setColumns] = useState([]);
  const [listData, setListData] = useState([]);
  const [readData, setReadData] = useState({});
  const pageNo = 1,
    pageLimit = 2000;
  useEffect(() => {
    loadNotificationList();
  }, []);

  const loadNotificationList = () => {
    setListData([]);
    setColumns([]);
    props.setLoading(true);
    getNotificationListAction(pageNo, pageLimit)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            if (Array.isArray(resp.data.rows)) {
              let data = resp.data.rows.map(v => ({
                id: v.ID,
                message: v.fullMessage,
                subject: v.subject,
                isRead: parseInt(v.isRead) === 1 ? "Read" : "Not Read",
                updatedAt: new Date(v.updatedAt).toLocaleString(),
              }));
              setListData(data);
              setColumns([
                {
                  dataField: "subject",
                  text: "Subject",
                  sort: true,
                },
                {
                  dataField: "isRead",
                  text: "Status",
                  sort: true,
                },
                {
                  dataField: "updatedAt",
                  text: "Last Activity",
                  sort: true,
                },
                {
                  dataField: "id",
                  text: "Action",
                  formatter: ActionButtons,
                  sort: false,
                },
              ]);
            }
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        let msg =
          "Something went wrong! Please try again or contact to Administration Department";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
              }
            }
          }
        }
        toast.error(msg);
        console.log("errerr", JSON.stringify(err.response.data));
        props.setLoading(false);
      });
  };
  const updateNotificationStatus = id => {
    updateNotificationReadStatusAction({ notificationId: id })
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          // let data = JSON.parse(JSON.stringify(listData));
          // for (let i in data) {
          //   if (data[i].id === id) {
          //     data[i].isRead = "Read";
          //   }
          // }
          // setListData(data);
        }
      })
      .catch(err => {
        let msg =
          "Something went wrong! Please try again or contact to Administration Department";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
              }
            }
          }
        }
        toast.error(msg);
        console.log("errerr", JSON.stringify(err.response.data));
        props.setLoading(false);
      });
  };
  const ActionButtons = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Link
        title="View Entire Message"
        className="text-success fa fa-list"
        to="#"
        onClick={() => {
          viewDetailsLinkClick(row);
        }}
      />
    );
  };
  const viewDetailsLinkClick = row => {
    let d = { ...row };
    setReadData(d);
    if (d.isRead !== "Read") {
      updateNotificationStatus(d.id);
    }
  };
  const backLinkClick = () => {
    setReadData({});
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Digi Styler Admin Portal Product</title>
        </MetaTags>

        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {Object.keys(readData).length > 0
                      ? `${readData.subject} `
                      : `Notification List`}
                    {Object.keys(readData).length > 0 ? (
                      <Link
                        style={{ float: "right" }}
                        className="btn btn-danger"
                        to="#"
                        onClick={backLinkClick}
                      >
                        Back
                      </Link>
                    ) : (
                      ""
                    )}
                  </CardTitle>

                  {columns.length > 0 && Object.keys(readData).length === 0 ? (
                    <DatatableTables columns={columns} data={listData} />
                  ) : (
                    ""
                  )}
                  {Object.keys(readData).length > 0 ? (
                    <Container fluid>
                      <Row>
                        <Col
                          className="col-12"
                          dangerouslySetInnerHTML={{ __html: readData.message }}
                        />
                        <p>{readData.updatedAt} </p>
                      </Row>
                    </Container>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IsLoadingHOC(Inbox, "Wait .....");
