import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
const RichTextEditor = props => {
  const [richtext, setRichtext] = useState("");
  useEffect(() => {
    // if (props.value) {
    //   setRichtext(
    //     EditorState.createWithContent(
    //       ContentState.createFromBlockArray(convertFromHTML(props.value))
    //     )
    //   );
    // } else {
    //   setRichtext(EditorState.createEmpty());
    // }
    setRichtext(EditorState.createEmpty());
  }, [props.value]);
  const handleOnChange = editorState => {
    if (props.parentCallback) {
      if (!editorState.getCurrentContent().hasText()) {
        props.parentCallback(props.id, "");
      } else {
        props.parentCallback(
          props.id,
          draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
      }
    }
    setRichtext(editorState);
  };
  return (
    <Editor
      editorState={richtext}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={handleOnChange}
    />
  );
};

export default React.memo(RichTextEditor);
