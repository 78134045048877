import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Modal, Spinner } from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import {
  getPartnerListAction,
  getWebsiteListAction,
  generateRegionTokenAction,
  getSideMenuListAction,
} from "../../store/actions";
import { toast } from "react-toastify";
import { parseJwt } from "../../helpers/common_helper";
class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
    let tData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    const tokenValue = parseJwt(tData.accessToken);
    this.state = {
      tokenValue: tokenValue,
      show: false,
      partnerList: [],
      siteList: [],
      partner: tokenValue.partnerId,
      site: tokenValue.siteId,
      loader: false,
      sideMenuList: localStorage.getItem("sideMenu")
        ? JSON.parse(localStorage.getItem("sideMenu"))
        : [],
    };
    this.tog_backdrop = this.tog_backdrop.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    if (
      !localStorage.getItem("sideMenu") ||
      !JSON.parse(localStorage.getItem("sideMenu"))
    ) {
      this.loadSideMenuList();
    }
    this.initMenu();
  }
  removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "partner") {
      this.setState({ ...this.state, [name]: value });
      this.loadWebsite(value);
    } else {
      this.setState({ ...this.state, [name]: value });
    }
  };
  loadSideMenuList = () => {
    localStorage.setItem("sideMenu", "[]");
    this.props.setLoading(true);
    getSideMenuListAction()
      .then(resp => {
        // this.props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            this.props.setLoading(false);
            let respData = resp.data;
            if (respData.rows) {
              let d = respData.rows.map((v, i) => {
                return {
                  id: v.ID,
                  name: v.name,
                  route: `${v.route}`,
                  subMenu: v.subMenu,
                  icon: `${v.icon}`,
                };
              });
              localStorage.setItem("sideMenu", JSON.stringify(d));
              this.setState({ ...this.state, sideMenuList: d });
            } else {
              this.setState({ ...this.state, sideMenuList: [] });
            }
          } else {
            localStorage.setItem("sideMenu", "[]");
            this.setState({ ...this.state, sideMenuList: [] });
          }
        } else {
          this.props.setLoading(false);
          localStorage.setItem("sideMenu", "[]");
          this.setState({ ...this.state, sideMenuList: [] });
        }
      })
      .catch(err => {
        this.props.setLoading(false);
        localStorage.setItem("sideMenu", "[]");
        this.setState({ ...this.state, sideMenuList: [] });
      });
  };
  loadWebsite = partnerid => {
    //this.props.setLoading(true);
    this.setState({
      ...this.state,
      loader: true,
      partner: partnerid,
      site: "",
      siteList: [],
    });
    getWebsiteListAction(partnerid)
      .then(resp => {
        // this.props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let respData = resp.data;
            if (respData.rows) {
              let d = respData.rows.map(v => {
                return {
                  id: v.ID,
                  status: v.status === 1 ? "Active" : "Inactive",
                  name: `${v.name}`,
                  description: `${v.description}`,
                  createdAt: `${new Date(v.createdAt).toDateString()}`,
                  siteUrl: v.siteUrl,
                  sitePerson: v.sitePerson,
                  remarks: `${v.remarks}`,
                  partnerId: v.partnerId,
                };
              });
              let findind = d.findIndex(
                v => v.id === this.state.tokenValue.siteId
              );
              let selectedSite = "";
              if (findind >= 0) {
                selectedSite = d[findind].id;
              }
              this.setState({
                ...this.state,
                siteList: d,
                loader: false,
                site: selectedSite,
              });
            } else {
              this.setState({ ...this.state, loader: false });
            }
          } else {
            this.setState({ ...this.state, loader: false });
          }
        } else {
          this.setState({ ...this.state, loader: false });
        }
      })
      .catch(err => {
        //this.props.setLoading(false);
        this.setState({ ...this.state, loader: false });
      });
  };
  tog_backdrop = () => {
    if (!this.state.show) {
      this.props.setLoading(true);
      this.setState({ ...this.state, loader: true });
      getPartnerListAction(1, 20000)
        .then(resp => {
          this.props.setLoading(false);
          if (resp.httpCode >= 200 && resp.httpCode <= 299) {
            let data = [];

            if (resp.data) {
              if (Array.isArray(resp.data.rows)) {
                data = resp.data.rows.map(v => ({
                  id: v.ID,
                  name: v.name,
                  code: v.code,
                  businessName: v.businessName,
                  pan: v.pan,
                  gstin: v.gstin,
                  email: v.email,
                  cell: v.phone1,
                }));
              }
            }
            this.setState({
              ...this.state,
              show: !this.state.show,
              partnerList: data,
              siteList: [],
              loader: false,
            });
            this.loadWebsite(this.state.tokenValue.partnerId);
          } else {
            this.setState({ ...this.state, loader: false });
          }
        })
        .catch(err => {
          this.setState({
            ...this.state,
            partnerList: [],
            siteList: [],
            loader: false,
          });
          toast.error("Error in Partner List load");
          this.props.setLoading(false);
        });
    } else {
      this.setState({ ...this.state, show: !this.state.show });
      this.removeBodyCss();
    }
  };
  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }
  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };
  generateNewToken = () => {
    if (!this.state.partner) {
      toast.error(`Please select Partner`);
      return false;
    } else if (
      this.state.tokenValue.type !== 0 &&
      !this.state.site &&
      this.state.siteList.length > 0
    ) {
      toast.error(`Please select Website`);
      return false;
    } else if (
      this.state.partner === this.state.tokenValue.partnerId &&
      this.state.site === this.state.tokenValue.siteId
    ) {
      toast.error(`Already login with same account`);
      return false;
    }
    this.props.setLoading(true);
    generateRegionTokenAction({
      partnerId: this.state.partner,
      siteId: this.state.site ? this.state.site : -1,
    })
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          let data = [];
          if (resp.data) {
            const tokenValue = parseJwt(resp.data.accessToken);
            this.setState({
              ...this.state,
              show: !this.state.show,
              tokenValue: tokenValue,
            });
            localStorage.setItem("authUser", JSON.stringify(resp.data));
            toast.success("Login Successfully");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            toast.error("Something went wrong");
          }
        }
        this.props.setLoading(false);
      })
      .catch(err => {
        toast.error("Error in Login load");
        console.log("err", err);
        this.props.setLoading(false);
      });
  };
  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="#">
                  <span style={{ color: "#a6b0cf", cursor: "default" }}>
                    <b>LogedIn:</b>{" "}
                    {`${
                      this.state.tokenValue.userName
                        ? this.state.tokenValue.userName
                        : " N/A"
                    } ${this.state.tokenValue.type === 0 ? "(Admin)" : ""}`}
                    <br />
                    <b> Partner:</b>{" "}
                    {`${
                      this.state.tokenValue.partnerName
                        ? this.state.tokenValue.partnerName
                        : " Not selected"
                    }`}
                    <br />
                    <b>Site:</b>{" "}
                    {`${
                      this.state.tokenValue.siteName
                        ? this.state.tokenValue.siteName
                        : " Not selected"
                    }`}
                  </span>
                  <br />
                  <span onClick={this.tog_backdrop} className="theme-link-href">
                    Change Partner/Site
                  </span>
                </Link>
              </li>
              <hr />
              {this.state.sideMenuList.map((v, i) => {
                return (
                  <li key={`menu-${i}`}>
                    <Link
                      to={v.route}
                      className={v.subMenu.length > 0 ? "has-arrow" : ""}
                    >
                      <i className={`${v.icon}`} />
                      <span>{this.props.t(v.name)}</span>
                    </Link>
                    {v.subMenu.length > 0 ? (
                      <ul className="sub-menu" aria-expanded="false">
                        {v.subMenu.map((v2, i2) => {
                          return (
                            <li key={`menu-${i}-sub-${i2}`}>
                              <Link to={v2.route}>{this.props.t(v2.name)}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </SimpleBar>
        <Modal
          isOpen={this.state.show}
          toggle={this.tog_backdrop}
          backdrop={"static"}
          id="staticBackdrop"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Partner Information
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={this.tog_backdrop}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Container>
              <Row md={2}>
                <Col className="pt-3">
                  <strong> Select Partner List:</strong>
                </Col>
                <Col className="pt-3">
                  <select
                    name="partner"
                    id="partner"
                    className="form-select"
                    onChange={this.handleChange}
                    disabled={this.state.loader}
                  >
                    <option value=""> ---Select Partner---</option>
                    {this.state.partnerList.map((v, i) => {
                      return (
                        <option
                          key={`p-${i}`}
                          selected={
                            v.id === this.state.tokenValue.partnerId
                              ? true
                              : false
                          }
                          value={v.id}
                        >{`${v.name} (${v.code})`}</option>
                      );
                    })}
                  </select>
                </Col>
                <Col className="pt-3 pb-5">
                  <strong>Select Site</strong>
                </Col>
                <Col className="pt-3 pb-5">
                  <select
                    name="site"
                    id="site"
                    className="form-select"
                    onChange={this.handleChange}
                    disabled={this.state.loader}
                  >
                    <option value=""> ---Select website---</option>
                    {this.state.siteList.map((v, i) => {
                      return (
                        <option
                          key={`p-${i}`}
                          selected={
                            v.id === this.state.tokenValue.siteId ? true : false
                          }
                          value={v.id}
                        >{`${v.name}`}</option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
              <Row md={1} xs={1} sm={1}>
                <Col className="text-center">
                  {this.state.loader ? (
                    <Spinner animation="border" variant="danger" />
                  ) : (
                    <Button
                      onClick={this.generateNewToken}
                      className="btn btn-success"
                    >
                      Login
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(
  withTranslation()(IsLoadingHOC(SidebarContent, "Wait ....."))
);
