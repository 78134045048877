import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import AddEditProduct from "./AddEditProduct";
import DatatableTables from "../../components/Tables/DatatableTables";
import { getProductListAction } from "../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { Link, useParams } from "react-router-dom";
import { GENDER_LIST } from "../../constants/config";
const Products = props => {
  const [columns, setColumns] = useState([]);
  const [listData, setListData] = useState([]);
  const { type, id } = useParams();
  const pageNo = 1,
    pageLimit = 1000;
  useEffect(() => {
    loadPartnerList();
  }, []);

  const loadPartnerList = () => {
    setListData([]);
    setColumns([]);
    props.setLoading(true);
    getProductListAction(pageNo, pageLimit)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            if (Array.isArray(resp.data.rows)) {
              let data = resp.data.rows.map(v => ({
                id: v.ID,
                name: v.name,
                code: v.code,
                gender: GENDER_LIST[v.gender],
                vendorCode: v.vendorCode,
                status: v.status === 1 ? "Active" : "Inactive",
                isPublish: v.isPublish ? "Yes" : "No",
                updatedAt: new Date(v.updatedAt).toLocaleString(),
                remarks: v.remarks,
                image: v.image,
              }));
              setListData(data);
              setColumns([
                {
                  dataField: "name",
                  text: "Name",
                  sort: true,
                },
                {
                  dataField: "gender",
                  text: "Gender",
                  sort: true,
                },
                {
                  dataField: "vendorCode",
                  text: "Vendor Code",
                  sort: true,
                },
                {
                  dataField: "status",
                  text: "Status",
                  sort: true,
                },
                {
                  dataField: "isPublish",
                  text: "Publish",
                  sort: true,
                },
                {
                  dataField: "updatedAt",
                  text: "Last Activity",
                  sort: true,
                },
                ,
                {
                  dataField: "image",
                  text: "Image",
                  formatter: ImageFormatter,
                  sort: true,
                },
                {
                  dataField: "id",
                  text: "Action",
                  formatter: ActionButtons,
                  sort: false,
                },
              ]);
            }
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        let msg =
          "Something went wrong! Please try again or contact to Administration Department";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
              }
            }
          }
        }
        toast.error(msg);
        console.log("errerr", JSON.stringify(err.response.data));
        props.setLoading(false);
      });
  };
  const ActionButtons = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Link
        title="Edit"
        className="text-success fa fa-edit"
        to={`/products/edit/${row.id}`}
      />
    );
  };
  const ImageFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.image) {
      return (
        // <Link
        //   title="Product Image"
        //   className="text-success"
        //   target="new"
        //   to={`${row.image}`}
        // >
        <img src={row.image} alt={`Product Image ${row.code}`} height="50" />
        // </Link>
      );
    } else {
      return "No Image";
    }
  };
  if (type === "new" || type === "edit") {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Digi Styler Admin Portal Add/Edit Patners</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col className="col-12">
                <AddEditProduct id={id} />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Digi Styler Admin Portal Product</title>
          </MetaTags>

          <Container fluid>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      Product List
                      <Link
                        to="/products/new"
                        className="btn btn-success"
                        style={{ float: "right" }}
                      >
                        Add Product
                      </Link>
                    </CardTitle>
                    {columns.length > 0 ? (
                      <DatatableTables columns={columns} data={listData} />
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
};

export default IsLoadingHOC(Products, "Wait .....");
