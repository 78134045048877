import React from "react";
import Switch from "react-switch";
const Offsymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      {props.text}
    </div>
  );
};
const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      {props.text}
    </div>
  );
};

const SwitchUI = props => {
  return (
    <Switch
      uncheckedIcon={<Offsymbol text={props.offText} />}
      checkedIcon={<OnSymbol text={props.onText} />}
      className="me-3 mb-sm-8 mb-3"
      onColor={props.onColor ? props.onColor : "#626ed4"}
      name={props.name}
      onChange={e => {
        props.parentCallback(e);
      }}
      checked={props.checked}
    />
  );
};
export default SwitchUI;
