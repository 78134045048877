import { get, post, put } from "../../helpers/api_helper";
export const getPersonListAction = partnerId => {
  return get(`/person${partnerId ? `?partnerId=${partnerId}` : ""}`);
};
export const getPersonDetailsAction = persionId => {
  return get(`/person/${persionId}`);
};

export const savePersonDetailsAction = (method = "post", data, id) => {
  if (method === "post") {
    return post(`/person`, data);
  } else {
    return put(`/person/${id}`, data);
  }
};
