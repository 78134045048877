import { get, post } from "../../helpers/api_helper";
export const getTicketListAction = (pageNo, limit) => {
  return get(`/tickets`);
};
export const getTicketDetailsAction = id => {
  return get(`/tickets/${id}`);
};

export const saveTicketDetailsAction = (data, id) => {
  if (!id) {
    return post(`/tickets`, data);
  } else {
    return post(`/tickets/${id}`, data);
  }
};
