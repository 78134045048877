export const USER_ROLL_LIST = {
  PRT_ADMIN: "Administrator",
  PRT_DATA_ENTRY: "Data Entry Operator",
};
export const PERSON_TITLE_LIST = { 1: "MR.", 2: "Mrs.", 3: "Ms." };
export const PRODUCT_CATEGORY_LIST = {
  1: "Garments",
  2: "Bottom",
  3: "Footwear",
  4: "Gogal",
  4: "Head",
};
export const GENDER_LIST = { F: "Female", M: "Male", NA: "Not Applicable" };
export const TICKET_STATUS_LIST = { open: "OPEN", closed: "CLOSED" };
export const TICKET_PRIORITY_LIST = {
  1: "LOW",
  2: "MEDIUM",
  3: "HIGH",
  4: "CRITICAL",
};
