import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { updatePaymentStatusAction } from "../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { Link, useHistory, useParams } from "react-router-dom";
const Payment = props => {
  const { id } = useParams();
  const history = useHistory();
  const queryString = window.location.search
    .replace("?", "")
    .split("&")
    .reduce(
      (r, e) => ((r[e.split("=")[0]] = decodeURIComponent(e.split("=")[1])), r),
      {}
    );
  useEffect(() => {
    if (Object.keys(queryString).length <= 1) {
      history.push("/");
    } else {
      updatePaymentStatus();
    }
  }, []);

  const updatePaymentStatus = () => {
    //props.setLoading(true);
    updatePaymentStatusAction(id, queryString)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        // props.setLoading(false);
        console.log("error", err);
        //toast.error("Error in Update Payment Status");
      });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Digi Styler Admin Portal Payment</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col className="col-3" />
            <Col className="col-6">
              <Card>
                <CardBody>
                  <Alert color="success" role="alert">
                    <CardTitle className="h4 text-center">
                      Payment Success
                    </CardTitle>
                    <Row md={2} sm={2} xs={2}>
                      <Col className="mt-3">
                        <strong>Process ID: </strong>
                      </Col>
                      <Col className="mt-3"> {id}</Col>
                      <Col className="mt-3">
                        <strong>Payment ID: </strong>
                      </Col>
                      <Col className="mt-3">
                        {queryString.razorpay_payment_id}
                      </Col>
                      <Col className="mt-3">
                        <strong>Payment Link ID: </strong>
                      </Col>
                      <Col className="mt-3">
                        {queryString.razorpay_payment_link_id}
                      </Col>
                      <Col className="mt-3">
                        <strong>Payment Link ID: </strong>
                      </Col>
                      <Col className="mt-3">
                        {queryString.razorpay_payment_link_id}
                      </Col>
                      <Col className="mt-3">
                        <strong>Payment Reference ID: </strong>
                      </Col>
                      <Col className="mt-3">
                        {queryString.razorpay_payment_link_reference_id}
                      </Col>
                      <Col className="mt-3">
                        <strong>Payment Status: </strong>
                      </Col>
                      <Col className="mt-3">
                        {queryString.razorpay_payment_link_status}
                      </Col>
                      <Col className="mt-3">
                        <strong>Payment Signature: </strong>
                      </Col>
                      <Col className="mt-3">
                        {queryString.razorpay_signature}
                      </Col>
                    </Row>
                    <Row md={1} xs={1}>
                      <Col className="text-center pt-5">
                        <Link to="/" className="btn btn-success">
                          Close
                        </Link>
                      </Col>
                    </Row>
                  </Alert>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IsLoadingHOC(Payment, "Wait .....");
