import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";
import ChangePassword from "../pages/Authentication/ChangePassword";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Patners
import Patners from "../pages/Patners";
// Products
import Products from "../pages/Products";
// Invoice
import InvoiceCreate from "../pages/InvoiceCreate";
import Invoice from "../pages/Invoice";
import Payment from "../pages/Payment";
import Reports from "../pages/Reports";
import Support from "../pages/Support";
import Inbox from "../pages/Inbox";
const authProtectedRoutes = [
  { path: "/dashboard", exact: true, component: Dashboard },
  //Partners
  { path: "/patners/:type/:id", exact: true, component: Patners },
  { path: "/patners/:type", exact: true, component: Patners },
  { path: "/patners", exact: true, component: Patners },
  //Products
  { path: "/products/:type/:id", exact: true, component: Products },
  { path: "/products/:type", exact: true, component: Products },
  { path: "/products", exact: true, component: Products },
  //Invoice
  { path: "/invoice", exact: true, component: Invoice },
  { path: "/invoice-create", exact: true, component: InvoiceCreate },

  //Reports
  { path: "/reports/:id", exact: true, component: Reports },
  //Support
  { path: "/support/:type/:id", exact: true, component: Support },
  { path: "/support/:type", exact: true, component: Support },
  { path: "/support", exact: true, component: Support },
  { path: "/inbox", exact: true, component: Inbox },
  { path: "/inbox/:id", exact: true, component: Inbox },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/change-password", component: ChangePassword },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/payment/:id", component: Payment },
];

export { authProtectedRoutes, publicRoutes };
