import { get, post, put } from "../../helpers/api_helper";
export const getWebsiteListAction = partnerId => {
  return get(`/site-record${partnerId ? `?partnerId=${partnerId}` : ""}`);
};
export const getWebsiteDetailsAction = id => {
  return get(`/site-record/${id}`);
};

export const saveWebsiteDetailsAction = (method = "post", data, id) => {
  if (method === "post") {
    return post(`/site-record`, data);
  } else {
    return put(`/site-record/${id}`, data);
  }
};
