import { get, post, put } from "../../helpers/api_helper";
export const getProductListAction = (pageno = 1, limit = 10) => {
  return get(`/item?page=${pageno}&limit=${limit}`);
};
export const getProductDetailsAction = id => {
  return get(`/item/${id}`);
};
export const saveProductDetailsAction = (method = "post", data, id) => {
  if (method === "post") {
    return post(`/item`, data);
  } else {
    return put(`/item/${id}`, data);
  }
};

export const uploadFileAction = body => {
  return post(`/upload-files`, body);
};
