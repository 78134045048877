import React, { useState, useEffect } from "react";
import { Col, Container, Row, Label, Button } from "reactstrap";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import DatatableTables from "../../components/Tables/DatatableTables";
import Select from "react-select";
import {
  getWebsiteListAction,
  getWebsiteDetailsAction,
  saveWebsiteDetailsAction,
  getPersonListAction,
} from "../../store/actions";
import { useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import SwitchUI from "components/SwitchUI";
import { toast } from "react-toastify";

const Website = props => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [personList, setPersonList] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const ActionButtons = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Link
        title="Edit"
        className="text-success fa fa-edit"
        onClick={() => {
          setId(row.id);
        }}
      />
    );
  };
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "siteUrl",
      text: "Website",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },
    {
      dataField: "id",
      text: "Action",
      formatter: ActionButtons,
      sort: false,
    },
  ];
  const [listData, setListData] = useState([]);
  let initialState = {
    id: props.id,
    partnerId: props.partnerId,
    name: "",
    description: "",
    siteUrl: "",
    sitePerson: [],
    status: 1,
    remarks: "",
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (id) {
      LoadDeatils(id);
    }
  }, [id]);
  useEffect(() => {
    if (props.partnerId) {
      LoadList(props.partnerId);
      LoadPersonList();
    }
  }, [props.partnerId]);
  const LoadPersonList = async () => {
    props.setLoading(true);
    getPersonListAction(props.partnerId)
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let respData = resp.data;
            if (respData.rows) {
              let d = [];
              for (let i in respData.rows) {
                d.push({
                  value: respData.rows[i].ID,
                  label: `${respData.rows[i].fName} ${respData.rows[i].lName} (${respData.rows[i].code})`,
                });
              }
              setPersonList(d);
            }
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  };
  const LoadDeatils = id => {
    props.setLoading(true);
    getWebsiteDetailsAction(id)
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let respdata = resp.data;
            let fData = JSON.parse(JSON.stringify(initialState));
            fData.id = respdata.ID;
            //fData.partnerId = respdata.partnerId;
            fData.status = respdata.status;
            fData.name = respdata.name;
            fData.description = respdata.description;
            fData.siteUrl = respdata.siteUrl;
            fData.sitePerson = respdata.sitePerson;
            fData.remarks = respdata.remarks;
            setFormData(fData);
          } else {
            toast.error("Invalid request");
            history.push("/patners");
          }
        } else {
          toast.error("Invalid request");
          history.push("/patners");
        }
      })
      .catch(err => {
        toast.error("Invalid request");
        history.push("/patners");
        props.setLoading(false);
      });
  };
  const LoadList = () => {
    props.setLoading(true);
    getWebsiteListAction(props.partnerId)
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let respData = resp.data;
            if (respData.rows) {
              let d = respData.rows.map(v => {
                return {
                  id: v.ID,
                  status: v.status === 1 ? "Active" : "Inactive",
                  name: `${v.name}`,
                  description: `${v.description}`,
                  createdAt: `${new Date(v.createdAt).toDateString()}`,
                  siteUrl: v.siteUrl,
                  sitePerson: v.sitePerson,
                  remarks: `${v.remarks}`,
                  partnerId: v.partnerId,
                };
              });
              setListData(d);
            }
          } else {
          }
        } else {
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  };
  const handleChange = (name, value) => {
    if (name === "sitePerson") {
      let d = [];
      for (let k in personList) {
        if (value.indexOf(personList[k].value) >= 0) d.push(personList[k]);
      }
      setSelectedPerson(d);
    }
    let fData = JSON.parse(JSON.stringify(formData));
    fData[name] = value;
    setFormData(fData);
  };
  const handleFormSubmitOfPerson = () => {
    if (formData.name.length < 3) {
      toast.error(`Please enter a Valid name`);
      return false;
    } else if (formData.siteUrl.length < 3) {
      toast.error(`Please enter a valid website URL`);
      return false;
    } else if (formData.sitePerson.length < 1) {
      toast.error(`Please select at least one Contact person`);
      return false;
    }
    props.setLoading(true);
    let requestBody = JSON.parse(JSON.stringify(formData));
    if (!requestBody.id) {
      delete requestBody.id;
    }
    saveWebsiteDetailsAction(
      formData.id ? "put" : "post",
      requestBody,
      formData.id
    )
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          toast.success("Website Information saved successfully");
          setFormData(initialState);
          LoadList(props.partnerId);
          setId(null);
        } else {
          toast.error(
            "Something went wrong! Please try again or contact to Administration Department"
          );
        }
      })
      .catch(err => {
        props.setLoading(false);
        let msg =
          "Something went wrong! Please try again or contact to Administration Department";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
                ${err.response.data.errors[i].error_msg}`;
              }
            }
          }
        }
        toast.error(msg);
        console.log("errerr", JSON.stringify(err.response.data));
      });
  };

  useEffect(() => {
    let d = [];
    let value = formData.sitePerson;
    for (let k in personList) {
      if (value.indexOf(personList[k].value) >= 0) d.push(personList[k]);
    }
    setSelectedPerson(d);
  }, [personList, formData.sitePerson]);
  if (props.partnerId) {
    return (
      <Container fluid>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: formData.name,
            siteUrl: formData.siteUrl,
            description: formData.description,
            sitePerson: formData.sitePerson,
            status: formData.status,
            remarks: formData.remarks,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Please enter Name"),
            siteUrl: Yup.string().required("Please Enter a valid website URL"),
            sitePerson: Yup.string().required(
              "Please select at least one Site Person"
            ),
          })}
          onSubmit={values => {
            //props.editProfile(values);
          }}
        >
          {({ errors, status, touched }) => (
            <Form className="form-horizontal">
              <Row md={2} lg={2} sm={1} xs={1}>
                <Col>
                  <Label for="ame" className="form-label">
                    Name *
                  </Label>
                  <Field
                    name="name"
                    type="text"
                    placeholder="Name"
                    values={formData.name}
                    disabled={props.websiteEditDisabled}
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    className={
                      "form-control" +
                      (errors.name && touched.name ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
                <Col>
                  <Label for="ame" className="form-label">
                    Website URL *
                  </Label>
                  <Field
                    name="siteUrl"
                    type="text"
                    placeholder={"Website URL"}
                    values={formData.siteUrl}
                    disabled={props.websiteEditDisabled}
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    className={
                      "form-control" +
                      (errors.siteUrl && touched.siteUrl ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="siteUrl"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
                <Col className="pt-1">
                  <Label for="ame" className="form-label">
                    Site Contact Person *
                  </Label>
                  <Select
                    value={selectedPerson}
                    options={personList}
                    isDisabled={props.websiteEditDisabled}
                    isMulti
                    onChange={e => {
                      let v = [];
                      if (e) {
                        for (let i in e) {
                          v.push(e[i].value);
                        }
                      }
                      handleChange("sitePerson", v);
                    }}
                    classNamePrefix="select2-selection"
                    name="sitePerson"
                  />

                  <ErrorMessage
                    name="sitePerson"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
                <Col className="pt-1">
                  <Label for="ame" className="form-label">
                    Status *
                  </Label>
                  <br />
                  <SwitchUI
                    parentCallback={value => {
                      handleChange("status", value ? 1 : 0);
                    }}
                    offText="Off"
                    onText="On"
                    checked={formData.status === 0 ? false : true}
                  />
                </Col>

                <Col>
                  <Label for="ame" className="form-label">
                    Description
                  </Label>
                  <Field
                    as="textarea"
                    name="description"
                    disabled={props.websiteEditDisabled}
                    maxLength="200"
                    placeholder={"Description "}
                    values={formData.description}
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    className={
                      "form-control" +
                      (errors.description && touched.description
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
                <Col>
                  <Label for="ame" className="form-label">
                    Remarks
                  </Label>
                  <Field
                    as="textarea"
                    name="remarks"
                    disabled={props.websiteEditDisabled}
                    maxLength="200"
                    placeholder={"Remarks "}
                    values={formData.remarks}
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    className={
                      "form-control" +
                      (errors.remarks && touched.remarks ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="remarks"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Row>
              {!props.websiteEditDisabled ? (
                <Row md={1}>
                  <Col className="text-center pt-2">
                    <Button
                      variant="success"
                      disabled={props.websiteEditDisabled}
                      className="btn btn-success"
                      onClick={handleFormSubmitOfPerson}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Form>
          )}
        </Formik>
        <Row md={1}>
          <h3>Website List</h3>
          <DatatableTables columns={columns} data={listData} />
        </Row>
      </Container>
    );
  } else {
    return <h5>Website can add/edit only when Partner is in edit mode</h5>;
  }
};

export default IsLoadingHOC(Website, "Wait .....");
