import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  InputGroup,
  Button,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import SwitchUI from "components/SwitchUI";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import {
  getCountryListAction,
  getStateListAction,
  getCityListAction,
  savePartnerDetailsAction,
  getPartnerDetailsAction,
} from "../../store/actions";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import ContactPerson from "./ContactPerson";
import Website from "./Website";
const AddEditPatner = props => {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const history = useHistory();
  const toggleTab = tab => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  };
  let initialState = {
    id: props.id,
    name: "",
    businessName: "",
    gstin: "",
    gstEffectiveDate: "",
    pan: "",
    status: 1,
    webSite: "",
    phone1: "",
    phone2: "",
    remarks: "",
    email: "",
    cityId: "",
    countryId: "",
    stateId: "",
    areaCode: "",
    address1: "",
    address2: "",
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (props.id) {
      LoadPartnerDeatils(props.id);
    }
  }, [props.id]);
  const LoadPartnerDeatils = id => {
    props.setLoading(true);
    getPartnerDetailsAction(id)
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let respdata = resp.data;
            let fData = JSON.parse(JSON.stringify(initialState));
            fData.name = respdata.name;
            fData.businessName = respdata.businessName;
            fData.gstin = respdata.gstin;
            fData.gstEffectiveDate = respdata.gstEffectiveDate;
            fData.pan = respdata.pan;
            fData.status = respdata.status;
            fData.webSite = respdata.webSite;
            fData.phone1 = respdata.phone1;
            fData.phone2 = respdata.phone2;
            fData.remarks = respdata.remarks;
            fData.email = respdata.email;
            fData.cityId = respdata.cityId;
            fData.countryId = respdata.countryId;
            fData.stateId = respdata.stateId;
            fData.areaCode = respdata.areaCode;
            fData.address1 = respdata.address1;
            fData.address2 = respdata.address2;
            setFormData(fData);
          } else {
            toast.error("Invalid request");
            history.push("/patners");
          }
        } else {
          toast.error("Invalid request");
          history.push("/patners");
        }
      })
      .catch(err => {
        toast.error("Invalid request");
        history.push("/patners");
        props.setLoading(false);
      });
  };
  useEffect(() => {
    loadCountryList();
  }, []);
  const loadCountryList = () => {
    props.setLoading(true);
    setCountryList([]);
    getCountryListAction()
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let data = [];
            if (Array.isArray(resp.data.rows)) {
              data = resp.data.rows;
            } else if (Array.isArray(resp.data)) {
              data = resp.data;
            }
            setCountryList(
              data.map((v, i) => ({
                value: v.ID,
                label: `${v.CountryName}(${v.AlphaCode3})`,
              }))
            );
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  };
  useEffect(() => {
    loadStateList();
  }, [formData.countryId]);
  const loadStateList = () => {
    props.setLoading(true);
    setStateList([]);
    setCityList([]);
    if (formData.countryId) {
      getStateListAction(formData.countryId)
        .then(resp => {
          props.setLoading(false);
          if (resp.httpCode >= 200 && resp.httpCode <= 299) {
            if (resp.data) {
              let data = [];
              if (Array.isArray(resp.data.rows)) {
                data = resp.data.rows;
              } else if (Array.isArray(resp.data)) {
                data = resp.data;
              }
              setStateList(
                data.map((v, i) => ({
                  value: v.ID,
                  label: v.StateName,
                }))
              );
            }
          }
        })
        .catch(err => {
          props.setLoading(false);
        });
    }
  };
  useEffect(() => {
    loadCityList();
  }, [formData.stateId]);
  const loadCityList = () => {
    props.setLoading(true);
    setCityList([]);
    if (formData.stateId) {
      getCityListAction(formData.stateId)
        .then(resp => {
          props.setLoading(false);
          if (resp.httpCode >= 200 && resp.httpCode <= 299) {
            if (resp.data) {
              let data = [];
              if (Array.isArray(resp.data.rows)) {
                data = resp.data.rows;
              } else if (Array.isArray(resp.data)) {
                data = resp.data;
              }
              setCityList(
                data.map((v, i) => ({
                  value: v.ID,
                  label: v.CityName,
                }))
              );
            }
          }
        })
        .catch(err => {
          props.setLoading(false);
        });
    }
  };
  const handleChange = (name, value) => {
    let fData = JSON.parse(JSON.stringify(formData));
    if (name === "gstin" || name === "pan") {
      fData[name] = value.toUpperCase();
    } else {
      fData[name] = value;
    }
    if (name === "countryId") {
      fData.stateId = "";
      fData.cityId = "";
    } else if (name === "stateId") {
      fData.cityId = "";
    }
    setFormData(fData);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const handleFOrmSubmitOfPartner = () => {
    if (formData.name.length < 3) {
      toast.error(`Please enter a Valid Partner name`);
      return false;
    } else if (formData.businessName.length < 3) {
      toast.error(`Please enter a Valid Partner business name`);
      return false;
    } else if (formData.email.length < 3 || !ValidateEmail(formData.email)) {
      toast.error(`Please enter a Valid Partner Email address`);
      return false;
    } else if (
      formData.phone1.length < 9 ||
      formData.phone1.length > 11 ||
      !phoneRegExp.test(formData.phone1)
    ) {
      toast.error(`Please enter a Valid Partner Phone number`);
      return false;
    } else if (!formData.countryId) {
      toast.error(`Please select Partner Country`);
      return false;
    } else if (!formData.stateId) {
      toast.error(`Please select Partner State`);
      return false;
    } else if (!formData.cityId) {
      toast.error(`Please select Partner City`);
      return false;
    } else if (formData.areaCode.length !== 6) {
      toast.error(`Please enter a Valid Area Code`);
      return false;
    }
    props.setLoading(true);
    let requestBody = JSON.parse(JSON.stringify(formData));
    if (!requestBody.id) {
      delete requestBody.id;
    }
    savePartnerDetailsAction(
      formData.id ? "put" : "post",
      requestBody,
      formData.id
    )
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          toast.success("Partner Information saved successfully");
          setFormData(initialState);
          history.push("/patners");
        } else {
          toast.error(
            "Something went wrong! Please try again or contact to Administration Department"
          );
        }
      })
      .catch(err => {
        props.setLoading(false);
        let msg =
          "Something went wrong! Please try again or contact to Administration Department";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
              }
            }
          }
        }
        toast.error(msg);
      });
  };
  const ValidateEmail = mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  return (
    <Container fluid>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">
                Partner Details
                <Link
                  style={{ float: "right" }}
                  className="btn btn-danger"
                  to="/patners"
                >
                  Back
                </Link>
              </h4>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({ current: activeTab === 1 })}
                    >
                      <NavLink
                        className={classnames({
                          current: activeTab === 1,
                        })}
                        onClick={() => {
                          setactiveTab(1);
                        }}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number">01</span> Partner Details
                      </NavLink>
                    </NavItem>

                    <NavItem
                      className={classnames({ current: activeTab === 2 })}
                    >
                      <NavLink
                        className={classnames({
                          active: activeTab === 2,
                        })}
                        onClick={() => {
                          setactiveTab(2);
                        }}
                        disabled={!(passedSteps || []).includes(2) && !props.id}
                      >
                        <span className="number">02</span> Contact Person
                      </NavLink>
                    </NavItem>

                    <NavItem
                      className={classnames({ current: activeTab === 3 })}
                    >
                      <NavLink
                        className={classnames({
                          active: activeTab === 3,
                        })}
                        onClick={() => {
                          setactiveTab(3);
                        }}
                        disabled={!(passedSteps || []).includes(3) && !props.id}
                      >
                        <span className="number">03</span> Website
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                <div className="content clearfix mt-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          name: formData.name,
                          businessName: formData.businessName,
                          email: formData.email,
                          phone1: formData.phone1,
                          phone2: formData.phone2,
                          address1: formData.address1,
                          address2: formData.address2,
                          countryId: formData.countryId,
                          stateId: formData.stateId,
                          cityId: formData.cityId,
                          areaCode: formData.areaCode,
                          remarks: formData.remarks,
                          pan: formData.pan,
                          gstin: formData.gstin,
                          gstEffectiveDate: formData.gstEffectiveDate,
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required(
                            "Please Enter Partner Name"
                          ),
                          businessName: Yup.string().required(
                            "Please enter Business Name"
                          ),
                          email: Yup.string()
                            .email("Please enter a valid Email")
                            .required("Please enter a valid Email Address"),
                          phone1: Yup.string()
                            .matches(
                              phoneRegExp,
                              "Please enter a valid Phone Number"
                            )
                            .required("Please enter a valid Phone Number"),
                          phone2: Yup.string().matches(
                            phoneRegExp,
                            "Please enter a valid Phone Number 2"
                          ),
                          address1: Yup.string().required(
                            "Please enter a valid address"
                          ),
                          countryId: Yup.string().required(
                            "Please select Country"
                          ),
                          stateId: Yup.string().required("Please select State"),
                          cityId: Yup.string().required("Please select City"),
                        })}
                        onSubmit={values => {
                          //props.editProfile(values);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <Row md={3} lg={3} sm={1} xs={1}>
                              <Col>
                                <Label for="ame" className="form-label">
                                  Name *
                                </Label>
                                <Field
                                  name="name"
                                  type="text"
                                  placeholder="Name"
                                  values={formData.name}
                                  disabled={props.partnerEditDisabled}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.name && touched.name
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col>
                                <Label for="ame" className="form-label">
                                  Business Name *
                                </Label>
                                <Field
                                  name="businessName"
                                  type="text"
                                  disabled={props.partnerEditDisabled}
                                  placeholder={"Business Name"}
                                  values={formData.businessName}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.businessName && touched.businessName
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="businessName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col>
                                <Label for="ame" className="form-label">
                                  PanNo.
                                </Label>
                                <Field
                                  name="pan"
                                  type="text"
                                  placeholder={"Pan No."}
                                  disabled={props.partnerEditDisabled}
                                  maxLength="11"
                                  values={formData.pan}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.pan && touched.pan
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="pan"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  GSTIN
                                </Label>
                                <Field
                                  name="gstin"
                                  type="text"
                                  placeholder="GSTIN"
                                  disabled={props.partnerEditDisabled}
                                  values={formData.gstin}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.gstin && touched.gstin
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="gstin"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  GSTIN Effective From
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    name="gstEffectiveDate"
                                    disabled={props.partnerEditDisabled}
                                    onChange={e => {
                                      handleChange(
                                        "gstEffectiveDate",
                                        `${e[0].getFullYear()}-${
                                          e[0].getMonth() + 1 > 9 ? "" : "0"
                                        }${e[0].getMonth() + 1}-${
                                          e[0].getDate() > 9 ? "" : "0"
                                        }${e[0].getDate()}`
                                      );
                                    }}
                                    value={
                                      formData.gstEffectiveDate
                                        ? new Date(formData.gstEffectiveDate)
                                        : ""
                                    }
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d",
                                    }}
                                  />
                                </InputGroup>
                                <ErrorMessage
                                  name="gstEffectiveDate"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Status
                                </Label>
                                <br />
                                <SwitchUI
                                  parentCallback={value => {
                                    handleChange("status", value ? 1 : 0);
                                  }}
                                  offText="Off"
                                  onText="On"
                                  checked={formData.status === 0 ? false : true}
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Website
                                </Label>
                                <Field
                                  name="webSite"
                                  type="text"
                                  values={formData.webSite}
                                  placeholder="Website"
                                  disabled={props.partnerEditDisabled}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.gstin && touched.gstin
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Email *
                                </Label>
                                <Field
                                  name="email"
                                  type="email"
                                  maxLength="100"
                                  values={formData.email}
                                  disabled={props.partnerEditDisabled}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  placeholder="Email"
                                  className={
                                    "form-control" +
                                    (errors.email && touched.email
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              </Col>

                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Phone No. *
                                </Label>
                                <Field
                                  name="phone1"
                                  type="number"
                                  maxLength="12"
                                  values={formData.phone1}
                                  placeholder="Phone No."
                                  disabled={props.partnerEditDisabled}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.phone1 && touched.phone1
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Alternate Phone No.
                                </Label>
                                <Field
                                  name="phone2"
                                  type="number"
                                  maxLength="12"
                                  values={formData.phone2}
                                  disabled={props.partnerEditDisabled}
                                  placeholder="Alternate Phone No."
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.phone2 && touched.phone2
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              </Col>

                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Address Line 1 *
                                </Label>
                                <Field
                                  name="address1"
                                  type="text"
                                  placeholder="Address Line 1 "
                                  values={formData.address1}
                                  disabled={props.partnerEditDisabled}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.address1 && touched.address1
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="address1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Address Line 2
                                </Label>
                                <Field
                                  name="address2"
                                  type="text"
                                  placeholder="Address Line 2 "
                                  values={formData.address2}
                                  disabled={props.partnerEditDisabled}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.address2 && touched.address2
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="address2"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Country *
                                </Label>
                                <Select
                                  value={
                                    formData.countryId
                                      ? countryList[
                                          countryList.findIndex(
                                            a => a.value === formData.countryId
                                          )
                                        ]
                                      : null
                                  }
                                  options={countryList}
                                  onChange={e => {
                                    handleChange("countryId", e.value);
                                  }}
                                  classNamePrefix="select2-selection"
                                  name="countryId"
                                  isDisabled={props.partnerEditDisabled}
                                />
                                <ErrorMessage
                                  name="countryId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  State *
                                </Label>
                                <Select
                                  value={
                                    formData.stateId
                                      ? stateList[
                                          stateList.findIndex(
                                            a => a.value === formData.stateId
                                          )
                                        ]
                                      : null
                                  }
                                  options={stateList}
                                  onChange={e => {
                                    handleChange("stateId", e.value);
                                  }}
                                  classNamePrefix="select2-selection"
                                  name="stateId"
                                  isDisabled={props.partnerEditDisabled}
                                />
                                <ErrorMessage
                                  name="stateId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  City *
                                </Label>
                                <Select
                                  value={
                                    formData.cityId
                                      ? cityList[
                                          cityList.findIndex(
                                            a => a.value === formData.cityId
                                          )
                                        ]
                                      : null
                                  }
                                  options={cityList}
                                  onChange={e => {
                                    handleChange("cityId", e.value);
                                  }}
                                  classNamePrefix="select2-selection"
                                  name="cityId"
                                  isDisabled={props.partnerEditDisabled}
                                />
                                <ErrorMessage
                                  name="cityId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Area Code *
                                </Label>
                                <Field
                                  type="number"
                                  name="areaCode"
                                  maxLength="6"
                                  disabled={props.partnerEditDisabled}
                                  placeholder={"Area Code "}
                                  values={formData.areaCode}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.areaCode && touched.areaCode
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="pin"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Remarks
                                </Label>
                                <Field
                                  as="textarea"
                                  name="remarks"
                                  maxLength="200"
                                  placeholder={"Remarks "}
                                  values={formData.remarks}
                                  disabled={props.partnerEditDisabled}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.remarks && touched.remarks
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="remarks"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>
                            {!props.partnerEditDisabled ? (
                              <Row md={1}>
                                <Col className="text-center pt-2">
                                  <Button
                                    disabled={props.partnerEditDisabled}
                                    variant="success"
                                    className="btn btn-success"
                                    onClick={handleFOrmSubmitOfPartner}
                                  >
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )}
                          </Form>
                        )}
                      </Formik>
                    </TabPane>
                    <TabPane tabId={2}>
                      <fieldset>
                        <legend> Contact Persons</legend>
                        <ContactPerson partnerId={formData.id} />
                      </fieldset>
                    </TabPane>
                    <TabPane tabId={3}>
                      <fieldset>
                        <legend> Website</legend>
                        <Website
                          websiteEditDisabled={props.partnerEditDisabled}
                          partnerId={formData.id}
                        />
                      </fieldset>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default IsLoadingHOC(AddEditPatner, "Wait .....");
