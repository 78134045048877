import React, { useState, useEffect } from "react";
import { Col, Container, Row, Label, Button, Modal, Alert } from "reactstrap";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import DatatableTables from "../../components/Tables/DatatableTables";
import {
  getPersonListAction,
  getPersonDetailsAction,
  savePersonDetailsAction,
} from "../../store/actions";
import { useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import SwitchUI from "components/SwitchUI";
import { toast } from "react-toastify";
import { USER_ROLL_LIST, PERSON_TITLE_LIST } from "../../constants/config";

const ContactPerson = props => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [cpid, setCpid] = useState(null);
  const ActionButtons = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Link
        title="Edit"
        className="text-success fa fa-edit"
        onClick={() => {
          setCpid(row.id);
        }}
      />
    );
  };
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "userName",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "role",
      text: "User Role",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },
    {
      dataField: "id",
      text: "Action",
      formatter: ActionButtons,
      sort: false,
    },
  ];
  const [listData, setListData] = useState([]);
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  function tog_backdrop(status) {
    setShow(status);
    removeBodyCss();
  }
  let initialState = {
    id: props.id,
    partnerId: props.partnerId,
    loginEnabled: 0,
    isDefault: false,
    type: 1,
    title: "",
    fName: "",
    lName: "",
    phone1: "",
    phone2: "",
    email1: "",
    email2: "",
    status: 1,
    remarks: "",
    role: "",
  };
  const [userInfo, setUserInfo] = useState({});
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (cpid) {
      LoadPersonDeatils(cpid);
    }
  }, [cpid]);
  useEffect(() => {
    if (props.partnerId) {
      LoadPersonList(props.partnerId);
    }
  }, [props.partnerId]);
  const LoadPersonDeatils = id => {
    props.setLoading(true);
    getPersonDetailsAction(id)
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let respdata = resp.data;
            let fData = JSON.parse(JSON.stringify(initialState));
            fData.id = respdata.ID;
            fData.partnerId = respdata.partnerId;
            fData.loginEnabled = respdata.loginEnabled;
            fData.isDefault = respdata.isDefault;
            fData.type = respdata.type;
            fData.title = respdata.title;
            fData.status = respdata.status;
            fData.fName = respdata.fName;
            fData.lName = respdata.lName;
            fData.phone1 = respdata.phone1;
            fData.phone2 = respdata.phone2;
            fData.remarks = respdata.remarks;
            fData.email1 = respdata.email1;
            fData.email2 = respdata.email2;
            fData.role = respdata.role;
            setFormData(fData);
          } else {
            toast.error("Invalid request");
            history.push("/patners");
          }
        } else {
          toast.error("Invalid request");
          history.push("/patners");
        }
      })
      .catch(err => {
        toast.error("Invalid request");
        history.push("/patners");
        props.setLoading(false);
      });
  };
  const LoadPersonList = () => {
    props.setLoading(true);
    getPersonListAction(props.partnerId)
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let respData = resp.data;
            if (respData.rows) {
              let d = respData.rows.map(v => {
                return {
                  id: v.ID,
                  status: v.status === 1 ? "Active" : "Inactive",
                  phone: `${v.phone1}${v.phone2 !== "" ? `, ${v.phone2}` : ""}`,
                  email: `${v.email1}${v.email2 !== "" ? `, ${v.email2}` : ""}`,
                  createdAt: `${new Date(v.createdAt).toDateString()}`,
                  role: USER_ROLL_LIST[v.role],
                  userName: v.userName,
                  name: `${v.fName} ${v.lName}`,
                  partnerId: v.partnerId,
                };
              });
              setListData(d);
            }
          } else {
          }
        } else {
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  };
  const handleChange = (name, value) => {
    let fData = JSON.parse(JSON.stringify(formData));
    fData[name] = value;
    setFormData(fData);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const ValidateEmail = mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  const handleFormSubmitOfPerson = () => {
    if (formData.fName.length < 3) {
      toast.error(`Please enter a Valid First name`);
      return false;
    } else if (formData.title.length < 1) {
      toast.error(`Please select Title`);
      return false;
    } else if (formData.email1.length < 3 || !ValidateEmail(formData.email1)) {
      toast.error(`Please enter a Valid Email address`);
      return false;
    } else if (formData.email2.length > 0 && !ValidateEmail(formData.email2)) {
      toast.error(`Please enter a Valid Alternate Email address`);
      return false;
    } else if (
      formData.phone1.length < 9 ||
      formData.phone1.length > 11 ||
      !phoneRegExp.test(formData.phone1)
    ) {
      toast.error(`Please enter a Valid Phone number`);
      return false;
    } else if (
      formData.phone2.length > 0 &&
      (formData.phone2.length < 9 ||
        formData.phone2.length > 11 ||
        !phoneRegExp.test(formData.phone2))
    ) {
      toast.error(`Please enter a Valid Alternate Phone number`);
      return false;
    } else if (formData.loginEnabled === 1 && formData.role.length < 3) {
      toast.error(`Please select User Roll`);
      return false;
    } else if (formData.phone1 === formData.phone2) {
      toast.error(`Alternate phone number is duplicate`);
      return false;
    } else if (formData.email1 === formData.email2) {
      toast.error(`Alternate email address is duplicate`);
      return false;
    }
    props.setLoading(true);
    let requestBody = JSON.parse(JSON.stringify(formData));
    if (!requestBody.id) {
      delete requestBody.id;
    }
    savePersonDetailsAction(
      formData.id ? "put" : "post",
      requestBody,
      formData.id
    )
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          toast.success("Person Information saved successfully");
          setFormData(initialState);
          LoadPersonList(props.partnerId);
          if (typeof resp.data === "object") {
            if (resp.data.userName) {
              setUserInfo(resp.data);
              tog_backdrop(true);
            }
          }
          setCpid(null);
        } else {
          toast.error(
            "Something went wrong! Please try again or contact to Administration Department"
          );
        }
      })
      .catch(err => {
        props.setLoading(false);
        let msg =
          "Something went wrong! Please try again or contact to Administration Department";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
            ${
              err.response.data.errors[i].error_msg
                ? err.response.data.errors[i].error_msg
                : err.response.data.errors[i].message
            }`;
              }
            }
          }
        }
        toast.error(msg);
        console.log("errerr", JSON.stringify(err.response.data));
      });
  };
  if (props.partnerId) {
    return (
      <Container fluid>
        <Formik
          enableReinitialize={true}
          initialValues={{
            fName: formData.fName,
            lName: formData.lName,
            email1: formData.email1,
            email2: formData.email2,
            phone1: formData.phone1,
            phone2: formData.phone2,
            loginEnabled: formData.loginEnabled,
            isDefault: formData.isDefault,
            title: formData.title,
            status: formData.status,
            role: formData.role,
            remarks: formData.remarks,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Please select Title"),
            fName: Yup.string().required("Please Enter First Name"),
            email1: Yup.string()
              .email("Please enter a valid Email")
              .required("Please enter a valid Email Address"),
            email2: Yup.string().email("Please enter a valid Email"),
            phone1: Yup.string()
              .matches(phoneRegExp, "Please enter a valid Phone Number")
              .required("Please enter a valid Phone Number"),
            phone2: Yup.string().matches(
              phoneRegExp,
              "Please enter a valid Phone Number 2"
            ),
          })}
          onSubmit={values => {
            //props.editProfile(values);
          }}
        >
          {({ errors, status, touched }) => (
            <Form className="form-horizontal">
              <Row md={3} lg={3} sm={1} xs={1}>
                <Col>
                  <Label for="ame" className="form-label">
                    Title *
                  </Label>
                  <Field
                    name="title"
                    as="select"
                    placeholder="Title"
                    values={formData.title}
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    className={
                      "form-control" +
                      (errors.title && touched.title ? " is-invalid" : "")
                    }
                  >
                    <option value="">Select Title</option>
                    {Object.keys(PERSON_TITLE_LIST).map((v, i) => {
                      return (
                        <option key={`title-${i}`} value={v}>
                          {PERSON_TITLE_LIST[v]}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
                <Col>
                  <Label for="ame" className="form-label">
                    First Name *
                  </Label>
                  <Field
                    name="fName"
                    type="text"
                    placeholder="First Name"
                    values={formData.fName}
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    className={
                      "form-control" +
                      (errors.fName && touched.fName ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="fName"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
                <Col>
                  <Label for="ame" className="form-label">
                    Last Name
                  </Label>
                  <Field
                    name="lName"
                    type="text"
                    placeholder={"Last Name"}
                    values={formData.lName}
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    className={
                      "form-control" +
                      (errors.lName && touched.lName ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="lName"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
                <Col className="pt-1">
                  <Label for="ame" className="form-label">
                    Email Address *
                  </Label>
                  <Field
                    name="email1"
                    type="email1"
                    maxLength="100"
                    values={formData.email1}
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    placeholder="Email Address"
                    className={
                      "form-control" +
                      (errors.email1 && touched.email1 ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email1"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
                <Col className="pt-1">
                  <Label for="ame" className="form-label">
                    Alternate Email Address
                  </Label>
                  <Field
                    name="email2"
                    type="email2"
                    maxLength="100"
                    values={formData.email2}
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    placeholder="Alternate Email Address"
                    className={
                      "form-control" +
                      (errors.email2 && touched.email2 ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email2"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
                <Col className="pt-1">
                  <Label for="ame" className="form-label">
                    Phone No. *
                  </Label>
                  <Field
                    name="phone1"
                    type="number"
                    maxLength="12"
                    values={formData.phone1}
                    placeholder="Phone No."
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    className={
                      "form-control" +
                      (errors.phone1 && touched.phone1 ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="phone1"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
                <Col className="pt-1">
                  <Label for="ame" className="form-label">
                    Alternate Phone No.
                  </Label>
                  <Field
                    name="phone2"
                    type="number"
                    maxLength="12"
                    values={formData.phone2}
                    placeholder="Alternate Phone No."
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    className={
                      "form-control" +
                      (errors.phone2 && touched.phone2 ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="phone2"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
                <Col className="pt-1">
                  <Row md={2}>
                    <Col>
                      <Label for="ame" className="form-label">
                        Status *
                      </Label>
                      <br />
                      <SwitchUI
                        parentCallback={value => {
                          handleChange("status", value ? 1 : 0);
                        }}
                        offText="Off"
                        onText="On"
                        checked={formData.status === 0 ? false : true}
                      />
                    </Col>
                    <Col>
                      <Label for="ame" className="form-label">
                        Login Enable
                      </Label>
                      <br />
                      <SwitchUI
                        parentCallback={value => {
                          handleChange("loginEnabled", value ? 1 : 0);
                        }}
                        offText="No"
                        onText="Yes"
                        onColor="#d9534f"
                        checked={formData.loginEnabled === 1 ? true : false}
                      />
                    </Col>
                  </Row>
                </Col>
                {formData.loginEnabled === 1 ? (
                  <Col className="pt-1">
                    <Label for="ame" className="form-label">
                      User Role *
                    </Label>
                    <Field
                      name="role"
                      as="select"
                      placeholder="User Role"
                      values={formData.role}
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      className={
                        "form-control" +
                        (errors.role && touched.role ? " is-invalid" : "")
                      }
                    >
                      <option value="">Select User Role</option>
                      {Object.keys(USER_ROLL_LIST).map((v, i) => {
                        return (
                          <option key={`role-${i}`} value={v}>
                            {USER_ROLL_LIST[v]}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="role"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              <Row md={1}>
                <Col>
                  <Label for="ame" className="form-label">
                    Remarks
                  </Label>
                  <Field
                    as="textarea"
                    name="remarks"
                    maxLength="200"
                    placeholder={"Remarks "}
                    values={formData.remarks}
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                    className={
                      "form-control" +
                      (errors.remarks && touched.remarks ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="remarks"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </Row>
              <Row md={1}>
                <Col className="text-center pt-2">
                  <Button
                    variant="success"
                    className="btn btn-success"
                    onClick={handleFormSubmitOfPerson}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <Row md={1}>
          <h3>Contact Person List</h3>
          <DatatableTables columns={columns} data={listData} />
        </Row>
        <Modal
          isOpen={show}
          toggle={() => {
            tog_backdrop(!show);
          }}
          backdrop={"static"}
          id="staticBackdrop"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              User Information
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShow(false);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Container>
              <Alert color="success" role="alert">
                Contact Person created and Login details given below please take
                a screenshot or copy the credentials.
              </Alert>
              <Row md={2}>
                <Col className="pt-3">
                  <strong> User Name:</strong>
                </Col>
                <Col className="pt-3">{userInfo.userName}</Col>
                <Col className="pt-3 pb-5">
                  <strong> Password:</strong>
                </Col>
                <Col className="pt-3 pb-5">{userInfo.password}</Col>
              </Row>
            </Container>
          </div>
        </Modal>
      </Container>
    );
  } else {
    return (
      <h5>Contact person can add/edit only when Partner is in edit mode</h5>
    );
  }
};

export default IsLoadingHOC(ContactPerson, "Wait .....");
