//import { GET_TASKS, GET_TASKS_FAIL, GET_TASKS_SUCCESS } from "./actionTypes"
import { get, post, put } from "../../helpers/api_helper";
export const getPartnerListAction = (pageno = 1, limit = 10) => {
  return get(`/partner?page=${pageno}&limit=${limit}`);
};
export const getPartnerDetailsAction = id => {
  return get(`/partner/${id}`);
};
export const savePartnerDetailsAction = (method = "post", data, id) => {
  if (method === "post") {
    return post(`/partner`, data);
  } else {
    return put(`/partner/${id}`, data);
  }
};
