export const getTokenDetails = () => {
  let localData = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : {};
  const accessToken = localData.accessToken ? localData.accessToken : "";
  let tokenData = null;
  if (accessToken) {
    let base64Url = accessToken.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    tokenData = JSON.parse(jsonPayload);
  }
  return tokenData;
};
export const parseJwt = token => {
  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } else {
    return false;
  }
};
