import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { Link } from "react-router-dom";
import { getDashboardDataAction } from "../../store/actions";
import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";

//i18n
import { withTranslation } from "react-i18next";

const Dashboard = props => {
  const initDashboardData = {
    periodData: { categories: [], values: [] },
    reports: [
      { title: "Total Items", iconClass: "bx-copy-alt", description: "0" },
      {
        title: "Today's Request",
        iconClass: "bx-archive-in",
        description: "0",
      },
      {
        title: "Last 30 Days",
        iconClass: "bx-purchase-tag-alt",
        description: "-",
      },
    ],
    lastMonthTrafficData: 0,
    lastMonthTrafficDataPer: 0,
  };
  //const reports =
  const [apiData, setApiData] = useState(initDashboardData);

  useEffect(() => {
    loadDashboardData();
    // setPeriodData([
    //   {
    //     name: "Series A",
    //     data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48],
    //   },
    //   {
    //     name: "Series B",
    //     data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
    //   },
    //   {
    //     name: "Series C",
    //     data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18],
    //   },
    // ]);
  }, []);
  const loadDashboardData = () => {
    props.setLoading(true);
    setApiData(initDashboardData);
    getDashboardDataAction()
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            setApiData({
              periodData: resp.data.trafficGraph1,
              lastMonthTrafficData: resp.data.reqCount30Days,
              lastMonthTrafficDataPer: "12%",
              reports: [
                {
                  title: "Product Count",
                  iconClass: "bx bx-purchase-tag",
                  description: `${resp.data.itemCount}`,
                },
                {
                  title: "Today's Hits",
                  iconClass: "bx bx-target-lock",
                  description: `${resp.data.reqCountToday}`,
                },
                {
                  title: "Last 30 Days Hits",
                  iconClass: "bx bx-spray-can",
                  description: `${resp.data.reqCount30Days}`,
                },
              ],
            });
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Digi Admin</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}

          <Row>
            <Col xl="4">
              <WelcomeComp />
              <MonthlyEarning
                last30DaysTraffic={apiData.lastMonthTrafficData}
                last30DaysTrafficPer={apiData.lastMonthTrafficDataPer}
              />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {apiData.reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Last 10 Days</h4>
                    <div className="ms-auto">
                      {/* <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly");
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly");
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly");
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart periodData={apiData.periodData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};
export default withTranslation()(IsLoadingHOC(Dashboard, "Wait ....."));
