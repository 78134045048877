import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  InputGroup,
  Button,
} from "reactstrap";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { createInvoiceAction } from "../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
const InvoiceCreate = props => {
  const initStateForm = { fromDt: "", toDt: "" };
  const [formData, setFormData] = useState(initStateForm);
  const handleChange = (key, value) => {
    let f = JSON.parse(JSON.stringify(formData));
    f[key] = value;
    setFormData(f);
  };
  const generateInvoice = () => {
    if (!formData.fromDt) {
      toast.error(`Please select From Date`);
      return false;
    } else if (!formData.toDt) {
      toast.error(`Please select To Date`);
      return false;
    }
    props.setLoading(true);
    createInvoiceAction({
      fromDate: formData.fromDt,
      toDate: formData.toDt,
    })
      .then(d => {
        toast.success(
          `Invoice Generation Process started wait for 10 minutes after that you can check it into Manage Invoice Section`
        );
        setFormData(initStateForm);
        props.setLoading(false);
      })
      .catch(err => {
        let msg =
          "Something went wrong! Please try again or contact to Administration Department";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
              }
            }
          }
        }
        toast.error(msg);
        console.log("errerr", JSON.stringify(err.response.data));
        props.setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Digi Styler Admin Portal Invoice</title>
        </MetaTags>

        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Generate Invoice</CardTitle>
                  <Row md={3} sm={1} xs={1}>
                    <Col className="pt-1">
                      <Label for="ame" className="form-label">
                        From Date
                      </Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          name="fromDt"
                          onChange={e => {
                            handleChange(
                              "fromDt",
                              `${e[0].getFullYear()}-${
                                e[0].getMonth() + 1 > 9 ? "" : "0"
                              }${e[0].getMonth() + 1}-${
                                e[0].getDate() > 9 ? "" : "0"
                              }${e[0].getDate()}`
                            );
                          }}
                          value={
                            formData.fromDt ? new Date(formData.fromDt) : ""
                          }
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col className="pt-1">
                      <Label for="ame" className="form-label">
                        To Date
                      </Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          name="toDt"
                          onChange={e => {
                            handleChange(
                              "toDt",
                              `${e[0].getFullYear()}-${
                                e[0].getMonth() + 1 > 9 ? "" : "0"
                              }${e[0].getMonth() + 1}-${
                                e[0].getDate() > 9 ? "" : "0"
                              }${e[0].getDate()}`
                            );
                          }}
                          value={formData.toDt ? new Date(formData.toDt) : ""}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d",
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col className="pt-4 text-center">
                      <Button
                        id="generateBtn"
                        disabled={
                          !formData.fromDt || !formData.toDt ? true : false
                        }
                        className="btn btn-success"
                        onClick={generateInvoice}
                      >
                        Generate
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IsLoadingHOC(InvoiceCreate, "Wait .....");
