import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
} from "reactstrap";
import IsLoadingHOC from "../../components/IsLoadingHOC";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// actions
import { changePasswordAction } from "../../store/actions";
import { toast } from "react-toastify";
const ChangePassword = props => {
  const initForm = { oldPassword: "", newPassword: "", confirmPass: "" };
  const [form, setForm] = useState(initForm);
  const submitForm = () => {
    let body = { ...form };
    if (body.oldPassword.length < 1) {
      toast.error(`Please enter a valid Old Password`);
      return false;
    } else if (body.newPassword.length < 1) {
      toast.error(`Please enter a valid New Password`);
      return false;
    } else if (body.newPassword !== body.confirmPass) {
      toast.error(`New Password and Old Password should be same`);
      return false;
    } else {
      props.setLoading(true);
      delete body.confirmPass;
      changePasswordAction(body)
        .then(d => {
          toast.success(`Password Changed successfully`);
          setForm(initForm);
          props.setLoading(false);
        })
        .catch(err => {
          let msg =
            "Something went wrong! Please try again or contact to Administration Department";
          if (err.response) {
            if (err.response.data) {
              if (Array.isArray(err.response.data.errors)) {
                msg = "";
                for (let i in err.response.data.errors) {
                  msg = `${msg}
                  ${
                    err.response.data.errors[i].error_msg
                      ? err.response.data.errors[i].error_msg
                      : err.response.data.errors[i].message
                  }`;
                }
              }
            }
          }
          toast.error(msg);
          console.log("errerr", err);
          props.setLoading(false);
        });
    }
  };
  const changeHandler = e => {
    const { name, value } = e.target;
    let f = { ...form };
    f[name] = value;
    setForm(f);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4 className="card-title mb-4">Change Password</h4>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  oldPassword: form.oldPassword,
                  newPassword: form.newPassword,
                  confirmPass: form.confirmPass,
                }}
                validationSchema={Yup.object().shape({
                  oldPassword: Yup.string().required(
                    "Please Enter Your Old Password"
                  ),
                  newPassword: Yup.string().required(
                    "Please Enter Your New Password"
                  ),
                  confirmPass: Yup.string().required(
                    "Please Enter confirm password"
                  ),
                  confirmPass: Yup.string().when("newPassword", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                      [Yup.ref("newPassword")],
                      "Both password need to be the same"
                    ),
                  }),
                })}
                onSubmit={values => {
                  submitForm(values);
                }}
              >
                {({ errors, status, touched }) => (
                  <Form className="form-horizontal" method="post">
                    <Row className="justify-content-center">
                      <Col md={6} xs={10} sm={8} className="pt-3">
                        <Label for="oldPassword" className="form-label">
                          Old Password
                        </Label>
                        <Field
                          name="oldPassword"
                          type="password"
                          values={form.oldPassword}
                          onChange={changeHandler}
                          className={
                            "form-control" +
                            (errors.oldPassword && touched.oldPassword
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="oldPassword"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col md={6} xs={10} sm={8} className="pt-3">
                        <Label for="newPassword" className="form-label">
                          New Password
                        </Label>
                        <Field
                          name="newPassword"
                          type="password"
                          values={form.oldPassword}
                          onChange={changeHandler}
                          className={
                            "form-control" +
                            (errors.newPassword && touched.newPassword
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="newPassword"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col md={6} xs={10} sm={8} className="pt-3">
                        <Label for="confirmPass" className="form-label">
                          Confirm Password
                        </Label>
                        <Field
                          name="confirmPass"
                          type="password"
                          values={form.oldPassword}
                          onChange={changeHandler}
                          className={
                            "form-control" +
                            (errors.confirmPass && touched.confirmPass
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="confirmPass"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col md={6} xs={10} sm={8} className="pt-3">
                        <div className="text-center mt-4">
                          <Button
                            type="button"
                            onClick={submitForm}
                            color="danger"
                          >
                            Change Password
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default IsLoadingHOC(ChangePassword, "Wait .....");
