import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import DatatableTables from "../../components/Tables/DatatableTables";
import {
  getInvoiceListAction,
  downloadInvoiceAction,
  emailNotificationAction,
  generatePaymentLinkAction,
} from "../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { Link } from "react-router-dom";
const Invoice = props => {
  const [columns, setColumns] = useState([]);
  const [listData, setListData] = useState([]);
  const pageNo = 1,
    pageLimit = 100;
  useEffect(() => {
    loadInvoiceList();
  }, []);

  const loadInvoiceList = () => {
    setListData([]);
    setColumns([]);
    props.setLoading(true);
    getInvoiceListAction(pageNo, pageLimit)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            if (Array.isArray(resp.data.rows)) {
              let data = resp.data.rows.map(v => ({
                id: v.ID,
                billAmount: v.billAmount,
                billDate: new Date(v.billDate).toLocaleString(),
                billTotalAmount: v.billTotalAmount,
                paymentStatus: v.paymentStatus,
                paymentStatusText:
                  v.paymentStatus === 1
                    ? "Paid"
                    : v.paymentStatus === 2
                    ? "In-Process"
                    : "UnPaid",
                billingPeriod: v.billingPeriod
                  ? v.billingPeriod
                  : "01 May 2022 to 31 May 2022",
              }));
              setListData(data);
              setColumns([
                {
                  dataField: "billingPeriod",
                  text: "Invoice Period",
                  sort: true,
                },
                {
                  dataField: "billDate",
                  text: "Invoice Date",
                  sort: true,
                },
                {
                  dataField: "billTotalAmount",
                  text: "Invoice Amount",
                  sort: true,
                },
                ,
                {
                  dataField: "paymentStatusText",
                  text: "Payment Status",
                  sort: true,
                },
                {
                  dataField: "id",
                  text: "Action",
                  formatter: ActionButtons,
                  sort: false,
                },
              ]);
            }
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error("Error in Partner List load");
        props.setLoading(false);
      });
  };
  const downloadPdf = (id, fileName) => {
    let requestBody = { invoiceId: id };
    props.setLoading(true);
    downloadInvoiceAction(requestBody)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let anchor = document.createElement("a");
            document.body.appendChild(anchor);
            anchor.href = resp.data.url;
            anchor.target = "new";
            anchor.download = fileName;
            anchor.click();
            window.URL.revokeObjectURL(resp.data.url);
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error("Error in PDF Download");
        props.setLoading(false);
      });
  };
  const EmailPdf = id => {
    let requestBody = {
      module: "ATTACHED_NOTIFICATION",
      templateTag: "INVOICE_ATTACHMENT",
      mediumType: ["email"],
      invoiceId: id,
    };
    props.setLoading(true);
    emailNotificationAction(requestBody)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            toast.success(
              `Invoice Sent successfully to your Email: ${resp.data.email}.`
            );
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error("Error in Email Send");
        props.setLoading(false);
      });
  };
  const makePayment = id => {
    let requestBody = { invoiceId: id };
    props.setLoading(true);
    generatePaymentLinkAction(requestBody)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let anchor = document.createElement("a");
            document.body.appendChild(anchor);
            anchor.href = resp.data.paymentLink.short_url;
            anchor.target = "new";
            //anchor.download = fileName;
            anchor.click();
            window.URL.revokeObjectURL(resp.data.url);
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error("Error in Payment Link generation");
        props.setLoading(false);
      });
  };
  const ActionButtons = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Link
          style={{ marginRight: "10px", fontSize: "20px" }}
          title="Invoice PDF Download"
          onClick={() => downloadPdf(row.id, `${row.billingPeriod}.pdf`)}
          className="text-success fas fa-file-pdf"
          to="#"
        />
        <Link
          style={{ fontSize: "20px", marginRight: "10px" }}
          title="Email Invoice"
          className="text-danger fa fa-envelope"
          onClick={() => EmailPdf(row.id)}
          to="#"
        />
        {!row.paymentStatus || row.paymentStatus === 0 ? (
          <Link
            style={{ fontSize: "20px" }}
            title="Make Invoice"
            className="text-primary fas fa-money-bill-wave"
            onClick={() => makePayment(row.id)}
            to="#"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Digi Styler Admin Portal Invoice</title>
        </MetaTags>

        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Invoice List</CardTitle>
                  {columns.length > 0 ? (
                    <DatatableTables columns={columns} data={listData} />
                  ) : (
                    <h5 className="text-center">No invoice generated</h5>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IsLoadingHOC(Invoice, "Wait .....");
